import SlideShowSlideComponent from '../components/SlideShowSlide/viewer/SlideShowSlide';


const SlideShowSlide = {
  component: SlideShowSlideComponent
};


export const components = {
  ['SlideShowSlide']: SlideShowSlide
};

